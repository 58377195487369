"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../../createSvgIcon"));

var _PagePrevious = _interopRequireDefault(require("@rsuite/icon-font/lib/legacy/PagePrevious"));

// Generated by script, don't edit it please.
var PagePrevious = (0, _createSvgIcon["default"])({
  as: _PagePrevious["default"],
  ariaLabel: 'page previous',
  category: 'legacy',
  displayName: 'PagePrevious'
});
var _default = PagePrevious;
exports["default"] = _default;
module.exports = exports.default;