"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../../createSvgIcon"));

var _Search = _interopRequireDefault(require("@rsuite/icon-font/lib/legacy/Search"));

// Generated by script, don't edit it please.
var Search = (0, _createSvgIcon["default"])({
  as: _Search["default"],
  ariaLabel: 'search',
  category: 'legacy',
  displayName: 'Search'
});
var _default = Search;
exports["default"] = _default;
module.exports = exports.default;