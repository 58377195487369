"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../../createSvgIcon"));

var _CheckCircle = _interopRequireDefault(require("@rsuite/icon-font/lib/legacy/CheckCircle"));

// Generated by script, don't edit it please.
var CheckCircle = (0, _createSvgIcon["default"])({
  as: _CheckCircle["default"],
  ariaLabel: 'check circle',
  category: 'legacy',
  displayName: 'CheckCircle'
});
var _default = CheckCircle;
exports["default"] = _default;
module.exports = exports.default;