"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../../createSvgIcon"));

var _Star = _interopRequireDefault(require("@rsuite/icon-font/lib/legacy/Star"));

// Generated by script, don't edit it please.
var Star = (0, _createSvgIcon["default"])({
  as: _Star["default"],
  ariaLabel: 'star',
  category: 'legacy',
  displayName: 'Star'
});
var _default = Star;
exports["default"] = _default;
module.exports = exports.default;