"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../../createSvgIcon"));

var _Info = _interopRequireDefault(require("@rsuite/icon-font/lib/legacy/Info"));

// Generated by script, don't edit it please.
var Info = (0, _createSvgIcon["default"])({
  as: _Info["default"],
  ariaLabel: 'info',
  category: 'legacy',
  displayName: 'Info'
});
var _default = Info;
exports["default"] = _default;
module.exports = exports.default;