/**
 * Source code reference from:
 * https://github.com/facebook/fbjs/blob/d308fa83c9/packages/fbjs/src/dom/translateDOMPositionXY.js
 */
import BrowserSupportCore from './utils/BrowserSupportCore';
import getVendorPrefixedName from './utils/getVendorPrefixedName';
import getGlobal from './utils/getGlobal';
var g = getGlobal();
var TRANSFORM = getVendorPrefixedName('transform');
var BACKFACE_VISIBILITY = getVendorPrefixedName('backfaceVisibility');

var appendLeftAndTop = function appendLeftAndTop(style, x, y) {
  if (x === void 0) {
    x = 0;
  }

  if (y === void 0) {
    y = 0;
  }

  style.left = x + "px";
  style.top = y + "px";
  return style;
};

var appendTranslate = function appendTranslate(style, x, y) {
  if (x === void 0) {
    x = 0;
  }

  if (y === void 0) {
    y = 0;
  }

  style[TRANSFORM] = "translate(" + x + "px," + y + "px)";
  return style;
};

var appendTranslate3d = function appendTranslate3d(style, x, y) {
  if (x === void 0) {
    x = 0;
  }

  if (y === void 0) {
    y = 0;
  }

  style[TRANSFORM] = "translate3d(" + x + "px," + y + "px,0)";
  style[BACKFACE_VISIBILITY] = 'hidden';
  return style;
};

export var getTranslateDOMPositionXY = function getTranslateDOMPositionXY(conf) {
  var _ref = conf || {},
      _ref$enableTransform = _ref.enableTransform,
      enableTransform = _ref$enableTransform === void 0 ? true : _ref$enableTransform,
      _ref$enable3DTransfor = _ref.enable3DTransform,
      enable3DTransform = _ref$enable3DTransfor === void 0 ? true : _ref$enable3DTransfor,
      forceUseTransform = _ref.forceUseTransform;

  if (forceUseTransform) {
    return conf.enable3DTransform ? appendTranslate3d : appendTranslate;
  }

  if (BrowserSupportCore.hasCSSTransforms() && enableTransform) {
    var ua = g.window ? g.window.navigator.userAgent : 'UNKNOWN';
    var isSafari = /Safari\//.test(ua) && !/Chrome\//.test(ua); // It appears that Safari messes up the composition order
    // of GPU-accelerated layers
    // (see bug https://bugs.webkit.org/show_bug.cgi?id=61824).
    // Use 2D translation instead.

    if (!isSafari && BrowserSupportCore.hasCSS3DTransforms() && enable3DTransform) {
      return appendTranslate3d;
    }

    return appendTranslate;
  }

  return appendLeftAndTop;
};
var translateDOMPositionXY = getTranslateDOMPositionXY();
export default translateDOMPositionXY;