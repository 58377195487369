"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../../createSvgIcon"));

var _ClockO = _interopRequireDefault(require("@rsuite/icon-font/lib/legacy/ClockO"));

// Generated by script, don't edit it please.
var ClockO = (0, _createSvgIcon["default"])({
  as: _ClockO["default"],
  ariaLabel: 'clock o',
  category: 'legacy',
  displayName: 'ClockO'
});
var _default = ClockO;
exports["default"] = _default;
module.exports = exports.default;