"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../../createSvgIcon"));

var _Spinner = _interopRequireDefault(require("@rsuite/icon-font/lib/legacy/Spinner"));

// Generated by script, don't edit it please.
var Spinner = (0, _createSvgIcon["default"])({
  as: _Spinner["default"],
  ariaLabel: 'spinner',
  category: 'legacy',
  displayName: 'Spinner'
});
var _default = Spinner;
exports["default"] = _default;
module.exports = exports.default;