"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../../createSvgIcon"));

var _CloseCircle = _interopRequireDefault(require("@rsuite/icon-font/lib/legacy/CloseCircle"));

// Generated by script, don't edit it please.
var CloseCircle = (0, _createSvgIcon["default"])({
  as: _CloseCircle["default"],
  ariaLabel: 'close circle',
  category: 'legacy',
  displayName: 'CloseCircle'
});
var _default = CloseCircle;
exports["default"] = _default;
module.exports = exports.default;