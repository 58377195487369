"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../../createSvgIcon"));

var _HelpO = _interopRequireDefault(require("@rsuite/icon-font/lib/legacy/HelpO"));

// Generated by script, don't edit it please.
var HelpO = (0, _createSvgIcon["default"])({
  as: _HelpO["default"],
  ariaLabel: 'help o',
  category: 'legacy',
  displayName: 'HelpO'
});
var _default = HelpO;
exports["default"] = _default;
module.exports = exports.default;